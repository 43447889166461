import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { GrayHowWeWork } from "./what-we-do"

const Hero = () => (
  <section>
    <div className="container px-5 md:px-12 mx-auto flex flex-col md:flex-row items-center ">
      <div data-aos="slide-right" className=" md:w-6/12">
        <h1 className="mt-16 md:mt-0 text-5xl text-pgray font-black leading-tight">
          Little Stone.
          <br /> <span className="font-hairline">Big Splash.</span>
        </h1>
        <p className="text-pgray2 text-base mt-4 text-justify md:text-left">
          That’s the simplest way to describe Touchcore. We’re a firm with
          diverse life experiences — but the same passion for designing and
          developing state-of-the-art software and web applications.
        </p>
      </div>
      <img
        data-aos="fade-left"
        alt=""
        className="md:w-6/12 md:p-10 mt-5 md:mt-0"
        src={require("./../assets/IMG_9204.png")}
      />
    </div>
  </section>
)
const PageQuote = () => (
  <section className="white-dots-bg bg-contain bg-no-repeat bg-bottom py-20 text-center">
    <div
      data-aos="zoom-in-up"
      data-aos-delay="300"
      className="container px-5 md:px-12 pb-20"
    >
      <h3 className="text-5xl font-medium text-pred text-center pb-8 md:pb-16">
        #exceed
      </h3>
      <p className="mx-auto md:w-8/12 text-pgray font-thin text-justify md:text-center md:font-semibold text-base md:text-xl leading-relaxed">
        In all we do, our goal is to exceed all expectations. Touchcore has
        built its business on creating value and helping businesses standout
        from the rest. We focus our energy on building solutions that work.
        Since our inception in 2015, we have built custom solutions that have
        become central components in our client’s business success. Touchcore is
        a client-oriented company with a “service” attitude. We are at your
        service. We can lead or follow. Our main goal is to maintain the highest
        quality standards and the best practices in today’s IT market.
      </p>
    </div>
  </section>
)

const OurPeople = () => (
  <section
    id="team"
    className="container px-5 md:px-12  mx-auto my-20 flex flex-col-reverse md:flex-row items-center"
  >
    <div data-aos="fade-right" className="md:w-5/12">
      <h6 className="text-2xl text-center md:text-left font-black text-pgray mt-6 md:mt-0">
        Our People
      </h6>
      <p className="text-pgray text-justify md:text-left mt-5">
        It’s the people that make Touchcore what we are. It’s their skills that
        help our clients achieve their goals, it’s their experience that helps
        to find the best solution to the problem, it’s their courage to
        experiment that lets us go beyond what is expected, and it’s their
        spirit that helps us build the Team
      </p>
      <div className="flex justify-center md:justify-start">
        <button className="explore-button mt-8">Join Us</button>
      </div>
    </div>
    <img
      data-aos="fade-left"
      className="md:w-6/12 ml-auto md:p-10"
      src={require("./../assets/IMG_1151.png")}
      alt=""
    />
  </section>
)
const OurAdvantages = () => {
  const data = [
    {
      title: "Transparency",
      content:
        "We believe that strong business relationships are built on trust and reliability.That’s why we offer fair, transparent pricing at every stage of your project.",
    },
    {
      title: "Quality",
      content:
        "If we had to describe the Touchcore culture in one word, it would be “quality”.We are serious about being the best in our industry, and our every effort is aimed at maintaining quality on all level, from the requirements to final delivery.",
    },
    {
      title: "Experience",
      content:
        "We’ve built custom applications for businesses in many different industries, including healthcare, education, oil and gas, entertainment, Logistics, wholesale and retail, finance, and more.",
    },
    {
      title: "Technology",
      content:
        "Our engineering team uses platforms and cutting- edge methods to deliver robust, reliable, and the utmost effective software solutions, adding value for our customers for years to come.",
    },
  ]
  return (
    <GrayHowWeWork
      title={"Our Advantage"}
      data={data}
      image={require("./../assets/blog-image.png")}
    />
  )
}
const OurCompany= () => (
  <Layout>
    <SEO title="Our Company" />
    <Hero />
    <PageQuote />
    <OurPeople />
    <OurAdvantages />
  </Layout>
)
export default OurCompany
